var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"role-settings pb-10"},[_c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('h1',{staticClass:"text-center"},[_vm._v(" Roles ")]),_c('p',{staticClass:"text-center"},[_vm._v(" Manage system users roles and permissions. ")]),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-right mt-3 mr-8",attrs:{"cols":"6"}},[(_vm.can('sms-create'))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showAddRoleDialog = true}}},[_c('v-icon',{attrs:{"left":"","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAdjust)+" ")]),_vm._v(" Add Role ")],1):_vm._e()],1)],1),_c('v-card-title',[_vm._v(" Roles "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.roles,"loading":_vm.isRolesLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticClass:"mt-2",attrs:{"indeterminate":"","rounded":""}}),_c('p',{staticClass:"text-center mt-1"},[_vm._v(" Loading...Please wait ")])]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [(_vm.roles.length > 0)?_c('tbody',_vm._l((items),function(role){return _c('tr',{key:role,staticClass:"clickable",on:{"click":function($event){return _vm.editRole(role)}}},[_c('td',[_vm._v(_vm._s(role))]),_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",on:{"click":function($event){return _vm.editRole(role)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),(role !== 'admin')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",on:{"click":function($event){return _vm.showDeleteRoleDialog(role)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1)])}),0):_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isRolesLoading),expression:"!isRolesLoading"}]},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.headers.length}},[_vm._v(" No customers found ")])])])]}}])}),_c('v-divider')],1),_c('add-role-dialog',{attrs:{"show-dialog":_vm.showAddRoleDialog,"role":_vm.role},on:{"close":function($event){_vm.showAddRoleDialog = false
      _vm.role = ''},"role-added":function($event){return _vm.getRoles()}}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmDeleteDialog,"is-agree-button-loading":_vm.isConfirmDeleteDialogButtonLoading,"agree-text":'Delete',"message":_vm.deleteRoleMessage},on:{"cancel":function($event){_vm.showConfirmDeleteDialog = false},"agree":function($event){return _vm.deleteRole()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }