<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Alert Contact</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <v-select
                    v-model="form.type"
                    :items="types"
                    item-text="name"
                    item-value="value"
                    label="Type"
                    :rules="[v => !!v || 'Type is required']"
                  ></v-select>
                  <small
                    v-show="form.errors.has('type')"
                    class="validation-error"
                  >{{
                    form.errors.get('type')
                  }}</small>
                </v-col>
                <v-col
                  v-show="form.type"
                  cols="8"
                >
                  <v-text-field
                    v-model="form.value"
                    :label="valueLabel"
                    :rules="valueValidationRules"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('value')"
                    class="validation-error"
                  >{{ form.errors.get('value') }}</small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      value: '',
      type: '',
    }),
    isRolesLoading: false,
    types: [
      { name: 'Email', value: 1 },
      { name: 'Phone', value: 2 },
    ],
    action: 'Save',
  }),
  computed: {
    valueLabel() {
      return this.form.type === 1 ? 'Email address' : 'Phone number'
    },
    valueValidationRules() {
      return this.form.type === 1 ? this.emailRules : this.phoneRules
    },
  },
  watch: {
    user() {
      if (Object.keys(this.user).length > 0) {
        this.form.update(this.user)
        this.getUserMeter(this.form.meter_id)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
    showDialog(value) {
      if (value && this.types.length === 0) {
        this.getRoles()
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveUser()
        } else {
          this.updateUser()
        }
      }
    },
    saveUser() {
      this.form
        .post('alert-contacts')
        .then(() => {
          this.$notification.success('System User added successfully')
          this.$emit('close')
          this.$emit('alert-contact-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    updateUser() {
      this.form
        .put(`alert-contacts/${this.form.id}`)
        .then(() => {
          this.$notification.success('System User updated successfully')
          this.$emit('close')
          this.$emit('alert-contact-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
