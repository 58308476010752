<template>
  <div class="alertContact-settings pb-10">
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <h1 class="text-center">
        Alert Contacts
      </h1>
      <p class="text-center">
        The system will send an alert to the contacts listed below if there are any critical issues that require the administrator's attention.
      </p>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          class="text-right mt-6 mr-8"
          cols="6"
        >
          <v-btn
            color="primary"
            @click="showAlertContactDialog = true"
          >
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiAccountMultiplePlus }}
            </v-icon>
            Add Alert Contact
          </v-btn>
        </v-col>
      </v-row>
      <br><br>
      <v-data-table
        :headers="headers"
        :items="alertContacts"
        :loading="isAlertContactsLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="alertContacts.length > 0">
            <tr
              v-for="alertContact in items"
              :key="alertContact.id"
            >
              <td>{{ alertContact.type | formatType }}</td>
              <td>{{ alertContact.value }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="showDeleteAlertContactDialog(alertContact.id, alertContact.name)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isAlertContactsLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No contacts found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <v-pagination
        v-model="pagination.page"
        class="mt-1"
        :length="pagination.pageCount"
        :total-visible="8"
        @input="onPageChange"
      ></v-pagination>
    </v-card>
    <add-alert-contact-dialog
      :show-dialog="showAlertContactDialog"
      :alert-contact="alertContact"
      @close="
        showAlertContactDialog = false
        alertContact = {}
      "
      @alert-contact-added="getAlertContacts()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      :message="deleteAlertContactMessage"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteAlertContact()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Form from 'vform'
import {
  mdiMagnify, mdiDelete, mdiAccountMultiplePlus,
} from '@mdi/js'
import AddAlertContactDialog from '@/components/dialogs/AddAlertContactDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    AddAlertContactDialog,
    ConfirmDialog,
  },
  filters: {
    formatType(value) {
      let type
      switch (value) {
        case 1:
          type = 'Email'
          break
        case 2:
          type = 'Phone'
          break

        default:
          type = 'Unknown'
          break
      }

      return type
    },
  },
  data() {
    return {
      sortBy: ['created_at'],
      sortDesc: [true],
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Type',
          align: 'start',
          value: 'type',
        },
        {
          text: 'Contact',
          value: 'value',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      isLoading: true,
      alertContact: {},
      alertContacts: [],
      alertContactsUrl: 'alert-contacts',
      isAlertContactsLoading: false,
      showAlertContactDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: null,
      toDeleteCustomerName: null,
      form: new Form({
        id: '',
        name: '',
        email: '',
        phone: '',
      }),
      icons: {
        mdiMagnify,
        mdiDelete,
        mdiAccountMultiplePlus,
      },
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.selectedStation}|${this.alertContactsUrl}|${this.sortBy}|${this.sortDesc}`
    },
    deleteAlertContactMessage() {
      return 'Are you sure you want to delete this contact from system alert contacts'
    },
  },
  mounted() {
    this.getAlertContacts()
  },
  methods: {
    editAlertContact(alertContact) {
      this.alertContact = alertContact
      this.showAlertContactDialog = true
    },
    showDeleteAlertContactDialog(id, name) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
      this.toDeleteCustomerName = name
    },
    getAlertContacts: _.debounce(function () {
      this.isAlertContactsLoading = true
      axios
        .get(
          `${this.alertContactsUrl}?sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&page=${this.pagination.page}`,
        )
        .then(response => {
          this.alertContacts = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isAlertContactsLoading = false
        })
        .catch(error => {
          this.isAlertContactsLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    save() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .put(`auth/admin/profile/${this.form.id}`)
          .then(() => {
            this.$notification.success('Settings updated successfully')
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
    deleteAlertContact() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`alert-contacts/${this.toDelete}`)
        .then(() => {
          this.$notification.success('AlertContact deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getAlertContacts()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onPageChange() {
      this.getAlertContacts()
    },
  },
}
</script>
