<template>
  <div class="user-settings pb-10">
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <h1 class="text-center">
        System Users
      </h1>
      <p class="text-center">
        The users listed below will be able to login and manage the system.
      </p>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          class="text-right mt-3 mr-8"
          cols="6"
        >
          <v-btn
            v-if="can('sms-create')"
            color="primary"
            @click="showAddUserDialog = true"
          >
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiAccountMultiplePlus }}
            </v-icon>
            Add System User
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-col class="col-md-4">
          <search-input v-model="search" />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :loading="isUsersLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="users.length > 0">
            <tr
              v-for="user in items"
              :key="user.id"
            >
              <td>{{ user.name }}</td>
              <td>{{ user.phone }}</td>
              <td>
                <span class="primary--text font-weight-medium">{{ user.email }}</span>
              </td>
              <td>
                <span class="primary--text font-weight-medium">{{ user.roles[0].name }}</span>
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="showDeleteUserDialog(user.id, user.name)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isUsersLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No customers found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <v-pagination
      v-model="pagination.page"
      class="mt-1"
      :length="pagination.pageCount"
      :total-visible="8"
      @input="onPageChange"
    ></v-pagination>
    <add-system-user-dialog
      :show-dialog="showAddUserDialog"
      :user="user"
      @close="
        showAddUserDialog = false
        user = {}
      "
      @user-added="getUsers()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      :message="deleteUserMessage"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteUser()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Form from 'vform'
import {
  mdiMagnify, mdiDelete, mdiAccountMultiplePlus,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import SearchInput from '@/components/partials/SearchInput.vue'
import AddSystemUserDialog from '@/components/dialogs/AddSystemUserDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    SearchInput,
    AddSystemUserDialog,
    ConfirmDialog,
  },
  mixins: [hasPermission],
  data() {
    return {
      sortBy: ['created_at'],
      sortDesc: [true],
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: false,
        },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'user_type', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      isLoading: true,
      user: {},
      users: [],
      userUrl: 'system-users',
      isUsersLoading: false,
      showAddUserDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: null,
      toDeleteCustomerName: null,
      form: new Form({
        id: '',
        name: '',
        email: '',
        phone: '',
      }),
      icons: {
        mdiMagnify,
        mdiDelete,
        mdiAccountMultiplePlus,
      },
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.selectedStation}|${this.userUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    deleteUserMessage() {
      return `Are you sure you want to delete ${this.toDeleteCustomerName} from system users`
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    editUser(user) {
      this.user = user
      this.showAddUserDialog = true
    },
    showDeleteUserDialog(id, name) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
      this.toDeleteCustomerName = name
    },
    getUsers: _.debounce(function () {
      this.isUsersLoading = true
      axios
        .get(
          `${this.userUrl}?sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&page=${this.pagination.page}`,
        )
        .then(response => {
          this.users = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isUsersLoading = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    save() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .put(`auth/admin/profile/${this.form.id}`)
          .then(() => {
            this.$notification.success('Settings updated successfully')
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
    deleteUser() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`users/${this.toDelete}`)
        .then(() => {
          this.$notification.success('User deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getUsers()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onPageChange() {
      this.getUsers()
    },
  },
}
</script>
