<template>
  <div class="role-settings pb-10">
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <h1 class="text-center">
        Roles
      </h1>
      <p class="text-center">
        Manage system users roles and permissions.
      </p>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          class="text-right mt-3 mr-8"
          cols="6"
        >
          <v-btn
            v-if="can('sms-create')"
            color="primary"
            @click="showAddRoleDialog = true"
          >
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiAdjust }}
            </v-icon>
            Add Role
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title>
        Roles
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="roles"
        :loading="isRolesLoading"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="roles.length > 0">
            <tr
              v-for="role in items"
              :key="role"
              class="clickable"
              @click="editRole(role)"
            >
              <td>{{ role }}</td>
              <td @click.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-3"
                      v-bind="attrs"
                      v-on="on"
                      @click="editRole(role)"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip
                  v-if="role !== 'admin'"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="showDeleteRoleDialog(role)"
                    >
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isRolesLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No customers found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <add-role-dialog
      :show-dialog="showAddRoleDialog"
      :role="role"
      @close="
        showAddRoleDialog = false
        role = ''
      "
      @role-added="getRoles()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      :message="deleteRoleMessage"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteRole()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiPencil, mdiDelete, mdiAdjust,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import AddRoleDialog from '@/components/dialogs/AddRoleDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    AddRoleDialog,
    ConfirmDialog,
  },
  mixins: [hasPermission],
  data() {
    return {
      headers: [
        { text: 'Role', value: 'role_type', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      isLoading: true,
      role: '',
      roles: [],
      roleUrl: 'roles',
      isRolesLoading: false,
      showAddRoleDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: null,
      toDeleteRole: null,
      icons: {
        mdiPencil,
        mdiDelete,
        mdiAdjust,
      },
    }
  },
  computed: {
    deleteRoleMessage() {
      return `Are you sure you want to delete ${this.toDeleteRole} from roles`
    },
  },
  mounted() {
    this.getRoles()
  },
  methods: {
    editRole(role) {
      this.role = role
      this.showAddRoleDialog = true
    },
    showDeleteRoleDialog(role) {
      this.showConfirmDeleteDialog = true
      this.toDelete = role
      this.toDeleteRole = role
    },
    getRoles: _.debounce(function () {
      this.isRolesLoading = true
      axios
        .get(
          `${this.roleUrl}`,
        )
        .then(response => {
          this.roles = response.data
          this.isRolesLoading = false
        })
        .catch(error => {
          this.isRolesLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    deleteRole() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`roles/${this.toDelete}`)
        .then(() => {
          this.$notification.success('Role deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getRoles()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onPageChange() {
      this.getRoles()
    },
  },
}
</script>

<style>
.clickable {
  cursor: pointer;
}
</style>
